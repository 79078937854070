import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
  <div className="col-md-6 col-xs-12">
  	<div className="blogpost">
  		<Link className="blog-link" to={post.frontmatter.cmsUserSlug}>
  			<div className="blogpost-bg">
  				<div className="blogpost-img" style={{background:`url(${post.frontmatter.banner})`}}>
  				</div>
  			</div>
	    </Link>
	    <div className="blogpost-text text-center">
		    <Link className="blog-link" to={post.frontmatter.cmsUserSlug}>
		    	<h5><strong>{post.frontmatter.title}</strong></h5>
	  			<p>{post.frontmatter.shortdesc}</p>
		    </Link>
		    <Link className="red-link" to={post.frontmatter.cmsUserSlug}>Read More</Link>
	    </div>
	    
	  </div>
  </div>
)

export default PostLink