import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/'
import PostLink from '../components/post-link'
import Bg1 from '../images/pexels-photo-776615.jpeg'
import Pdf1 from '../pdf/How-to-Build-a-Highly-Efficient-Sales-Structure-Part-1.pdf'
import Pdf2 from '../pdf/Establishing-Your-Sales-Territories-and-Sales-Responsibilities.pdf'
import Pdf3 from '../pdf/How-to-Build-a-Highly-Efficient-Sales-Structure-Part-1.pdf'
import Pdf4 from '../pdf/How-to-Build-a-Highly-Efficient-Sales-Structure-Part-1.pdf'

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout head={{ title: 'Blog Posts' }}>
      <div
        id="hero-section"
        className="bg-cover"
        style={{ background: `url(${Bg1})` }}
      >
        <div className="container">
          <div className="slim-content">
            <h1>Blog and Case Studies</h1>
          </div>
        </div>
      </div>
      <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="row">{Posts}</div>
            </div>
            <div className="col-md-4">
              <h2 className="text-red text-center mt-30">Case Studies</h2>
              <div className="row">
                <div className="col-12 mt-30">
                  <div className="studies">
                    <h4>Part 1: Foundations for Success</h4>
                    <p>
                      Building a highly-efficient sales structure is a complex
                      but essential undertaking for the successful sales
                      organization.
                    </p>
                    <a
                      href={Pdf1}
                      target="_blank"
                      className="btn red-btn "
                      rel="noopener noreferrer"
                    >
                      <i class="fa fa-file-pdf-o" aria-hidden="true" /> FREE
                      DOWNLOAD
                    </a>
                  </div>
                </div>
                <div className="col-12 mt-30">
                  <div className="studies">
                    <h4>
                      Part 2: How to Build a Highly-Efficient Sales Structure
                    </h4>
                    <p>
                      Establishing Your Sales Territories and Sales
                      Responsibilities
                    </p>
                    <a
                      href={Pdf2}
                      target="_blank"
                      className="btn red-btn "
                      rel="noopener noreferrer"
                    >
                      <i class="fa fa-file-pdf-o" aria-hidden="true" /> FREE
                      DOWNLOAD
                    </a>
                  </div>
                </div>
                <div className="col-12 mt-30">
                  <div className="studies">
                    <h4>Part 3: Defining Your Sales Team Requirements</h4>
                    <p>
                      Building a highly-efficient sales structure is not
                      complete – nor even possible – without people.
                    </p>
                    <a
                      href={Pdf3}
                      target="_blank"
                      className="btn red-btn "
                      rel="noopener noreferrer"
                    >
                      <i class="fa fa-file-pdf-o" aria-hidden="true" /> FREE
                      DOWNLOAD
                    </a>
                  </div>
                </div>
                <div className="col-12 mt-30">
                  <div className="studies">
                    <h4>
                      Part 4: Recruiting, Hiring and Onboarding Top Sales
                      Performers
                    </h4>
                    <p>
                      Building a highly-efficient sales structure is complex.
                      And one of the most complex, yet critical, components of
                      implementing and maintaining an efficient sales structure
                      is in the selection, hiring and integration of new
                      salespeople.
                    </p>
                    <a
                      href={Pdf4}
                      target="_blank"
                      className="btn red-btn "
                      rel="noopener noreferrer"
                    >
                      <i class="fa fa-file-pdf-o" aria-hidden="true" /> FREE
                      DOWNLOAD
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            author
            banner
            shortdesc
            updatedDate
            cmsUserSlug
            categories
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
